/* eslint-disable import/prefer-default-export */
export const fieldsMainProduct = [
  {
    key: 'checkbox',
    label: '',
    thClass: 'w-[10px]',
    tdClass: 'bg-[#FFECE9]',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'w-[600px] bg-[#FFECE9] text-[#222]',
  },
  {
    key: 'total',
    label: 'Jumlah',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center',
    tdClass: 'bg-[#FFECE9] text-[#222]',
  },
]

export const fieldsAddonProduct = [
  {
    key: 'checkbox',
    label: '',
    thClass: 'w-[10px]',
    tdClass: '',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'w-[600px] text-[#222]',
  },
  {
    key: 'total',
    label: 'Jumlah',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center',
    tdClass: 'text-[#222]',
  },
]

export const fieldsDiscountProduct = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'text-[#222]',
  },
  {
    key: 'total',
    label: 'Jumlah',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'text-[#222]',
  },
  {
    key: 'price',
    label: 'Harga Normal',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'text-[#222]',
  },
  {
    key: 'discount',
    label: 'Diskon',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'text-[#222] w-[150px]',
  },
  {
    key: 'final_price',
    label: 'Harga Paket',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize',
    tdClass: 'text-[#222]',
  },
]

export const fieldsBundling = [
  {
    key: 'no',
    label: 'No',
    thClass: 'font-[500] text-[#6e6b7b] !text-[14px] !capitalize bg-white',
    tdClass: 'text-[#6e6b7b]',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#6e6b7b] !text-[14px] !capitalize bg-white',
    tdClass: 'text-[#6e6b7b]',
  },
  {
    key: 'qty',
    label: 'Jumlah',
    thClass: 'font-[500] text-[#6e6b7b] !text-[14px] !capitalize text-center bg-white',
    tdClass: 'text-[#6e6b7b] text-center',
  },
]
